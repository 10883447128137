/* .projectTable{
    border-spacing: 0px;
}
.projectTable tr,.projectTable td{
    border-top:1px solid #c1c1c1 ;
    padding: 5px;
}
.projectTable tr,.projectTable td,.projectTable th{
    border-right: 1px solid #000000;
    padding: 5px 10px;;
}

.projectTable .last-tr:hover{
    background-color: #fff;
}
.projectTable tbody tr:hover{
  background: #c1c1c1;
  cursor: pointer;
}
.projectTable tbody .last-tr:hover{
  cursor: auto;
} */
.inf-table{
  padding: 0px!important;
}
.outer {
    display: flex;
    flex-flow: column;
    height: 100vh;
  }
  
  
  .inner_remaining {
    flex-grow: 1;
  }
  
  .mytab {
    width: 100%;
    height: 100%;
  }
  .projectright{
    padding-left: 13%;
    padding-right: 13%;

  }
  .leftPanel-td tbody tr:hover{
    background: #efefef;
    cursor: pointer;
  }
  .searchCSBox{
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
  }
  .searchCSBox:focus{
    border: none;
    border-bottom: 1px solid skyblue;
  }
  .padd3{
    padding-left: 3%;

  }
  .padd2{
    padding-left: 2%;
  }
  .pagehead{
    color: #1a598e!important;
    font-size: 23px!important;
    font-weight: 600!important;
    padding-left: 6px;
  }
  .pheadt{
    font-size: 16px;
    font-weight: 400;
  }
  .pheadtt{
    font-size: 16px;
    font-weight: 600;
  }
  .tddata{
    min-width: 250px;

  }
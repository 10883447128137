.gr-bg{
    background: rgb(54,122,159);
background: linear-gradient(118deg, rgba(54,122,159,1) 0%, rgba(43,116,157,1) 33%, rgba(175,122,200,1) 100%);
padding-top: 8%;
height: 100vh;
overflow: hidden;
}
.login-container{
    background: #1A588C;
    max-width: 350px !important;
    border-radius: 20px;
    color: #fff;
    padding-top: 20px;;
    border: 1px solid #1a588c;
    box-shadow: 0px 0px 20px 0px #282828;
}
.login-container h1{
    font-weight: bold;
}
.logIp{
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 10px 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.login-btn{
    padding: 10px 20px;
    background: #3c6bd3;
    border: 1px solid #fff;
    font-weight: bold;
    border-radius: 20px;
    margin: 10px;
    width: 200px;
    color: #fff;
    cursor: pointer;

}
.login-fogrt{
cursor: pointer;
}

.paassIp{
    -webkit-text-security: disc;
text-security: disc;
}
body{
    /* overflow: hidden; */
}
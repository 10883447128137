.cal-con td,
.cal-con th
{
    min-height: 16px;;
    height: 21px;
    text-align: center;
}
.imageUrl{
    text-align: center;
    border:1px solid #ccc;
    border-radius: 8px;
}

    ._cl_dt_cell {
        border: 1px solid #ccc;
        border-radius: 6px;
        font-size: 11px;
        text-align: center;
        height: 20px;
        width: 14px;
        padding: 4px 8px;
        margin: auto;
        max-width: 100%;
    
    }
    ._user_cl_th{
        background: #fff;
    }
    ._user_cl_th th{
        font-weight: normal
    }
    ._user_cl_th th div{
        border: 1px solid #ebebeb;
        padding: 7px 5px;
        border-radius: 6px;
        background: rgb(250,250,250);
        background: linear-gradient(160deg, #e0efff 0%, #dddeff 100%);;
        font-size: 12px;
    }
    div.__dlcl-paper .MuiDialog-paper{
        border-radius: 0px !important;;
    }
    ._dialog-title-cl{
        background-color: #ccc;
        padding: 6px 26px !important;
        font-size: 15px !important;
    }
    ._user_cl_container{
        overflow-y: scroll;
    max-height: 75vh;
    }
    ._tr_display{
        border-bottom: 2px solid;
    }
    .filhead{
        color: #0e60a5;
        font-size: 19px;
        margin-top: 3px;
        margin-bottom: 3px;
    }
    .accche{
        min-width: 20px;
        padding: 2px!important;
    }
    .listitem{
        padding: 0px 0px 0px;
    }
    .uldev{
        padding: 0px 1px 0px;

    }
    .file-loader-cr span{ width: 100%; }
    .uploadfile{
        float: right;
    padding: 3px 10px 4px 7px;
    border: 1px solid #d9d2d2;
    border-radius: 15px;
    background: #154c7a;
    color: #fff;
    }
    .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
        margin: 2px 0!important;
    }
    .user-row-tr:hover{
        background-color: #f5f5f5;
    }
    td.trip-td-item {
        cursor: pointer;
    }
    td.name-td{
        background-color: #fff;
    }
    .user-row-tr:hover td.name-td{
        background-color: #f5f5f5;
    }
    
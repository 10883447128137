.clientTable{
  border-spacing: 0px;
}
.clientTable tr,.clientTable td{
  border-top:1px solid #c1c1c1 ;
  padding: 5px;
}
.clientTable tr,.clientTable td,.clientTable th{
  border-right: 1px solid #000000;
  padding: 5px 10px;;
}

.clientTable .last-tr:hover{
  background-color: #fff;
}
.clientTable tbody tr:hover{
background: #c1c1c1;
cursor: pointer;
}
.clientTable tbody .last-tr:hover{
cursor: auto;
}

.outer {
  display: flex;
  flex-flow: column;
  height: 100vh;
}


.inner_remaining {
  flex-grow: 1;
}

.mytab {
  width: 100%;
  height: 100%;
}
.clientright{
  padding-left: 13%;
  padding-right: 13%;

}
.padd2{
  padding-left: 2%;

}
.pagehead{
  color: #1a598e!important;
  font-size: 23px!important;
  font-weight: 600!important;
  padding-left: 6px;
}
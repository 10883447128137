.tripTable{
    border-spacing: 0px;
}
.tripTable tr,.tripTable td{
    border-top:1px solid #c1c1c1 ;
    padding: 5px;
}
.tripTable tr,.tripTable td,.tripTable th{
    border-right: 1px solid #c1c1c1;
    padding: 5px 5px;;
}

.tripTable .last-tr:hover{
    background-color: #fff;
}

.outer {
    display: flex;
    flex-flow: column;
    height: 100vh;
  }
  
  
  .inner_remaining {
    flex-grow: 1;
  }
  .tabpad{
    padding: 3px 3px!important;
    font-size: 0.7rem;

  }
  .mytab {
    width: 100%;
    height: 100%;
  }
  .tripright{
    padding-left: 13%;
    padding-right: 13%;

  }
  ._trip-day,._no-trip{
    display: inline-block;
    min-width: 10px;
    width: 100%;
    height: 20px;
    position: relative;
    z-index: 1;

  }
  #yearCal ._trip-day, ._no-trip{
    max-width: 40px;
  }
  ._trip_event-cnt{
    position: absolute;
    left: 0px;
    right: 0px;
  }
  .trip-td-item{
    min-width: 40px;
  }
  ._tCell-prName{
    position: absolute;
    left: 0px;
    z-index: 700;
    color: #FFF;
    text-shadow: 1px 1px 0px #000;
    font-size: 13px;
    padding-left: 3px;
  }
  ._opec_cell{
    opacity: 0.5;
  }
  ._cal_table{
    overflow-x: hidden;
  white-space: nowrap;
  border-collapse: collapse;
    box-sizing: border-box;
    border-spacing: 0px;
  }

  ._cal_table td{
    padding: 7px 0px;
  }
  ._cal_table th,._cal_table td{
    box-shadow: 1px 1px inset #ccc;
    border-top: 1px solid #ccc ;
  }
  ._cal_table td,._cal_table th{
   
    position: relative;
    min-width: 10px;
  }
  ._cal_table th{
    padding: 10px;
    border: 0px solid #ccc;
    width: 42px;
    box-shadow: 1px 1px inset #ccc;
    border-top: 1px solid #ccc ;
  }   
  /* .tr_1 .brd_blk{
    border-right:1px solid black !important;
  }
  td.brd_blk,th.brd_blk{
    border-right:1px solid black !important;
  } */
  .fl-span{
    float: left;
  }
  .fr-span{
    float: right;
  }
  ._cal_table  td.name-td{
    padding: 1px;
    z-index: 1200;
    background: #fff;
    border-right: 1px solid #efefef;
  }

tr th{
  background: #FFF;
  font-weight: normal;
}
._t_filter_box{
  display:inline-block;
  /* width: 25%; */
  padding-right: 2%;
  border:1px solid #ccc;
  background-color: #eee;
  border-radius: 50px;
  font-size: 12px;
  cursor: pointer;
  margin-right: 15px;
}
._tr_year_block {
  border: 1px solid #ccc;
  box-shadow: 1px 2px 10px 4px #ffffff;
  border-radius: 50px;
  padding: 9px 0px 13px 0px;
  margin-right: 10px;
}
.btnmgtop{
  margin-top: -9px!important;

}
.cale{
  padding-top: 7px;

}
.nobg{
  background: none!important;

}
.pheads{margin-top: -10px;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 14px!important;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
   margin: 5px 0!important;
}
.pagehead{
  color: #1a598e!important;
  font-size: 23px!important;
  font-weight: 600!important;
  padding-left: 6px;
}
.phead{
  color: rgba(0, 0, 0, 0.87);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.alignright{
  text-align: right;
    align-self: center;
}
._tr_add_btn{
  float: right;
}
.paddingtop6{
  padding: 6px;
}
th._cl_today,td._cl_today{
  border-left: 2px solid #ed4040;
    border-right: 1px solid #ed4040;
}
._cal_table  thead{
    background: white;
    position: sticky;
    top: 0;
    z-index: 1000;
  
}
.hasAction{
  color:#1976d2;
  cursor: pointer;
}
.hasAction:hover{
  color: #26629d;
}
.summary-viewbox .MuiGrid-grid-md-12{
  margin-bottom: 15px;
}
.sortItem-arr{
  font-size: 16px;
    padding-top: 7px;
    display: inline-block;
    color: #7c7c7c;
}
.active-th{
  background-color: #f7f7f7 !important;
}
.active-th .sortItem-arr{
  color: #1976d2;
}
.leftPanel-td th{
  cursor: pointer;
}
.sort-desc .sortItem-arr{
  transform: rotate(179deg);
}
._tr_fileList{
  list-style: none;
  margin: 0px;
  padding: 0px;
}
._tr_fileList li{
  cursor: pointer;
  color: #1976d2;
}
.file-cntr{
  border: 1px solid #e9e9e9;
  padding: 15px 11px 18px 20px;
  border-radius: 8px;
}
.padd3{
  padding-left: 3%;

}
._tr_cstab{
  margin-bottom: 0px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    max-width: 360px;
    min-width: 90px;
    position: relative;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 12px 16px;
    overflow: hidden;
    white-space: normal;
    text-align: center;
    display: inline-block;
    border-bottom: 2px solid #fff;
    cursor: pointer;
}
._selected_tab{
  color: #1976d2;
  border-bottom: 2px solid #1976d2;
}
.table-cl .mondayLink{
  box-shadow: 1px 0px inset #13ff1b !important;
  border-left:0px !important;
  
}
.MuiBackdrop-root {
  z-index: 100000;
}
.main-header{
    background: #1A588C !important;
}
.settings-container{
    margin-top: 100px;
}
thead{
    background: #e7e7e7;
}
.borderradiuoss{
    border-radius: 23px!important;
}
@media print {
    /* .body{
        display: none!important;
    }
    .accborder{
        display: none!important;
    }
    .hiheader{
        display: none!important;
    }
    .bodyf{
        display: none!important;
    }
    .css-hz1bth-MuiDialog-container{
        display: none!important;
    }
    .css-rnmm7m-MuiPaper-root-MuiDialog-paper{
        display: none!important;
    }
    
    .form-dialog{
        display: block!important;
    } */
    .form-dialog{
        position: fixed;
        width: 100%;
        height: 100%;
        background: #fff;
        left: 0px;
        top: 0px;
    }
    .hide-print{
        display: none !important;
    }
    .print-sm1{
        width: 79%;
        float: left;
    }
    .print-sm2{
        width: 20%;
        float: left;
    }
    .MuiDialog-container{
        opacity: 1!important;
        background: #fff;
    }
}
.MuiDialog-paper{border-radius: 20px !important;}
.inf-table {
    padding: 15px;
}
.inf-table td{
    padding: 8px 5px;
    font-size: 13px;;
}
.inf-table td:first-child{
    font-weight: bold;
}
.textRight{
    text-align: right;
}
.textLeft{
    text-align: left;
}
.pl-20{
    padding-left: 20px;
}
.table-v2 td:first-child{
    padding-left: 20px;
    min-width:20%;
}
._menu-active{
    background-color: #1565c0  !important;
}
.v2-right-pane{
    min-height: 95vh;
    border-left: 1px solid #ccc;
}
.main{
    margin-top: 70px;
}
.form-dialog .MuiGrid-item {
    margin-bottom: 20px;
    color: black;
    
}
.MuiDialog-paper.form-dialog,.form-dialog-view{
    background:#e2e2e2
}
.form-dialog-view{
    padding: 4px 45px 30px 45px;
}
.form-dialog-view .MuiGrid-item {margin-bottom: 15px;}
.search-box{
    border: 1px solid #8b8b8b;
    box-shadow: none;
    border-radius: 60px !important;
}
.acclist .MuiListItemIcon-root {
    min-width: 14px !important;
}
.acclist .MuiListItemButton-gutters {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.filterhead{
    color: #1a598e!important;
    font-size: 23px!important;
    font-weight: 600!important;
    padding-left: 6px;
}
.filterclear
{
    color: #1a598e!important;
   text-align: right;
   padding-left: 44%;
   cursor: pointer;
}
.filterclear:hover{

    text-decoration: underline;
}
.acclist{
    padding-left: 20px;
    padding-right: 20px; 
}
.acclist .MuiCheckbox-root,.acclist .MuiListItemButton-root{padding: 0px !important;
}
.acclist .Mui-expanded {
margin-bottom: 0px !important;
margin-top: 0px !important;
}
.acclist .MuiAccordionDetails-root,.acclist .MuiAccordionDetails-root ul{
    padding: 1px 5px 16px !important;
}
.acclist .MuiAccordionSummary-root{
    padding-left: 0px;

min-height: 45px  !important;
}
.accborder{
    border-top: 1px solid #130030;
    border-radius: 0px!important;
    box-shadow:none!important;

}
.accorhead{
    color: #150135;
    font-size: 15px!important;
    font-weight: 600!important;
}
.acccolor{
    color: #150135!important;
    
}
.accche{
    color: #bdbdbd!important;
    margin-right: 5px;
    padding-right:1px;
}
.boxshadow{
    box-shadow:none!important;
}
.viewdet{
    border-width: 0 0 1px;
    border-style: solid;
    color: #150135!important;
    font-weight: 500!important;
}
.viecomment{
    color: #3b8100;
    font-size: 27px!important;

}
.sortbox{
    border-radius: 20px!important;
    color: #040404!important;
}
.marginresume{
    margin-top: 10px
}
.blackcolor{
    color: #000000!important;
    margin-top: 10px;
}
.blk,.colorBlack{
    color: #000000;
}
.viewresbtn{
    color: #ffffff!important;
    background: #356ed7!important;
    padding: 6px!important;
    border-radius: 23px!important;
    border: 1px solid #565353!important;
    min-width: 118px!important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    
}
.viewresbtn.Mui-disabled{
    background: #afc6f1!important;
}
.addicon{
    padding-top: 3px!important;
    margin: -4px!important;
}
.vpuplecor{
    color: #150135;

}
.upd-btn{
    padding: 10px 13px;
    display: inline-block;
    background: #356ed7;
    border-radius: 21px;
    margin-left: 15px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
}
.hide{
    display: none;
}
.logohead{
    color: #fff;
    text-decoration: none;
}
.loadTool .MuiToolbar-gutters{
    min-height: 24px !important;
}
.containerDiv{
    color:#919191;
}
.containerDiv strong{
    color:#000000;
}
.view-res-dialog .MuiPaper-rounded{
    border-radius: 30px;
}
.dialog-title-elm{
    
    margin-bottom: 0px !important;
    padding-bottom:  0px !important;
}
.list-sub-container .sub-h4{
    border-bottom:1px solid #a5a5a5;
}
.list-sub-container  .commentUL {
    width: 100%;
    max-width: 100%;
}
.list-sub-container  .commentUL li span,.list-sub-container  .commentUL li p{
    display: inline;
    margin-right: 10px;;color: #150135!important;
}
.commentUL li p{
    font-weight: bold;
    color:#000;
}
.list-sub-container .cmt-divider{ display: none;}
.close-pos{
   padding:0px !important;
   text-align: end;
}
.action-item{
    cursor: pointer;
}
.sett-table thead th{
    padding: 15px;;
}
.sett-table tbody td{
   
    padding: 0px 10px;
}
.sett-table tbody tr:hover{
    background: #f5f3f3;
}
.emupad{
    padding-left: 10px;
}

.settingsnbtn{
    float: right;
    margin-top: 7%!important;
}
.boxnone{
    box-shadow: none!important;
}
.padtop{
    margin-top: 38px!important;
}
.sethead{
    margin-top: 0px;
    color: #1a598e!important;
    font-size: 23px!important;
    font-weight: 600!important;
    padding-left: 6px;
}
.closepad{
    margin-top: 7%!important;
}
.usclosepad{
    margin-top: 5%!important;
}
.padlefth{    padding-left: 5px!important;
}
.nopad{    margin: 37px 45px 30px 30%;}
.redumehead{
    
    margin-top: 20px;
}
.close-span{
    position: absolute;
    top: 5px;
    right: 5px;
    margin-bottom: 10px;
    display: block;
}
.title-padded-cmt{
    margin-top: 8px !important;
}
.viewListComment .MuiGrid-item {
    margin-bottom: 2px !important;
}
.borderradiuoss{
    border-radius: 23px!important;
}
.reshead{
    margin-top: 2%!important;
    padding-bottom: 13px;
}
.newbox{
    border: 1px solid #c2c2c2!important;
    box-shadow: none!important;
    height: 39px!important;
}
.newbox:hover{
    border: 1px solid #000000!important;
    
}
.newbox:active{
    border: 1px solid #005aad!important;
}
.p-chip{
    word-break: break-all;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 20px;
    background: #d0d0d0;
    cursor: pointer;
}
.clearSearch{
    font-size: 13px;
    color: #1a588c;
    cursor: pointer;
}
.clearSearch:hover{
    text-decoration: underline;
}
.leftSearch{
    margin-top: 0px;
    padding-top: 0px;
}
.leftSearch input{
    border: 1px solid #ccc !important;
    border-radius: 5px;
    margin-left: -25px;
    padding-left: 10px;;
    padding-top: 10px;
    padding-bottom: 10px;
}
.viweMore{
    padding: 0px;
    text-align: right;
    color: #005aad;
    font-size: 12px;
    padding-bottom: 5px;
}
.viweMore span{
    float: right;
    cursor: pointer;
}
.docFound{
    position: absolute;
    top: 2px;
    right: -5px;
    color: yellow;
}
.docFound svg{
    font-size: 16px;
}
.tabHeader{
    border-bottom: 1px solid #ccc;
    margin-bottom: 25px !important;
}
.tabItem{
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
}
.selectedTab{
    font-weight: bold;
}
.yearPick-box{
    width:100% !important;
}
.yearPick-box .MuiOutlinedInput-input{
    padding:10px !important
}
.perhead{
    margin-bottom: -13px;

}